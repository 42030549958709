(function() {
    'use strict';

    angular.module('ionicAnalytics', [
        'ionicAlert',
        'ionicData',
        'ionicDataTable',
        'ionicLogin',
        'ionicRequest',
        'ionicUser',
        'uabDefaultVariable',
        'uabEnvironment',
        'uabMoment',
        'uabRedirect'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicAnalytics').controller(
        'AnalyticsController',
        AnalyticsController
    );

    AnalyticsController.$inject = [
        'AnalyticsService',
        'LoginService',
        'RedirectService'
    ];

    function AnalyticsController(
        AnalyticsService,
        LoginService,
        RedirectService
    ) {
        var AnalyticsController = this;

        AnalyticsController.clear = clear;
        function clear() {
            AnalyticsController.reset();
            AnalyticsService.reset();
        }

        AnalyticsController.reset = reset;
        function reset() {
            AnalyticsController.analytics = {};
        }

        AnalyticsController.trackExternalState = trackExternalState;
        function trackExternalState(data) {
            return AnalyticsService.trackExternalState(data);
        }

        AnalyticsController.trackInternalState = trackInternalState;
        function trackInternalState(data) {
            return AnalyticsService.trackInternalState(data);
        }

        AnalyticsController.init = init;
        function init() {
            AnalyticsController.reset();

            RedirectService.registerBeforeExternalRedirect(
                AnalyticsController.trackExternalState
            );

            RedirectService.registerBeforeInternalRedirect(
                AnalyticsController.trackInternalState
            );
        }

        LoginService.register(AnalyticsController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicAnalytics').directive('ionicAnalytics', ionicAnalytics);

    function ionicAnalytics() {
        return {
            controller:   'AnalyticsController'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicAnalytics').service(
        'AnalyticsService',
        AnalyticsService
    );

    AnalyticsService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'EnvironmentService',
        'ErrorService',
        'LoginService',
        'ListManagementService',
        'MomentService',
        'PromiseManagementService',
        'RequestManagementService',
        'UserService',
        '$state'
    ];

    function AnalyticsService(
        DataManagementService,
        DefaultVariableService,
        EnvironmentService,
        ErrorService,
        LoginService,
        ListManagementService,
        MomentService,
        PromiseManagementService,
        RequestManagementService,
        UserService,
        $state
    ) {
        var AnalyticsService = this;

        AnalyticsService.getCurrentDate = getCurrentDate;
        function getCurrentDate() {
            return MomentService.toDatabaseFormat(
                MomentService()
            );
        }

        AnalyticsService.trackExternalState = trackExternalState;
        function trackExternalState(sref) {
            return AnalyticsService.trackState(sref, []);
        }

        AnalyticsService.trackInternalState = trackInternalState;
        function trackInternalState(data) {
            var params =  DefaultVariableService.get(
                data.options
            );

            var state = DefaultVariableService.getString(
                data.sref
            );

            return AnalyticsService.trackState(state, params);
        }

        AnalyticsService.trackState = trackState;
        function trackState(sref, params) {
            return LoginService.getActiveUser().then(
                function(activeUser) {
                    if (activeUser) {
                        var currentDatetime = AnalyticsService.getCurrentDate();

                        var seconds = (currentDatetime.valueOf() - AnalyticsService.previousDatetime.valueOf()) / 1000;

                        var analyticStateVisit = {
                            from_state:          DefaultVariableService.getString($state.current.name),
                            params:              JSON.stringify(params),
                            seconds:             DefaultVariableService.getInteger(seconds),
                            to_state:            DefaultVariableService.getString(sref),
                            transition_datetime: currentDatetime,
                            user_id:             DefaultVariableService.getInteger(activeUser.id)
                        };

                        var options = RequestManagementService.getRequest();

                        options = RequestManagementService.setModel(options, 'analytic_state_visits');
                        options = RequestManagementService.setAction(options, 'add');

                        options = RequestManagementService.setData(options, analyticStateVisit);

                        return DataManagementService.request(options).then(
                            function(result) {
                                if (result) {
                                    AnalyticsService.previousDatetime = currentDatetime;

                                    return true;
                                }

                                return false;
                            }
                        );
                    }

                    return false;
                }
            );
        }

        AnalyticsService.trackVideo = trackVideo;
        function trackVideo(newAnalyticVideo) {
            var playTime = DefaultVariableService.getInteger(
                newAnalyticVideo.play_time,
                0
            );

            var stopTime = DefaultVariableService.getInteger(
                newAnalyticVideo.stop_time,
                playTime
            );

            var watchTime = stopTime - playTime;

            var requiredWatchTime = EnvironmentService.get(
                'requiredWatchTime',
                1
            );

            if (watchTime >= requiredWatchTime) {
                return LoginService.getActiveUser().then(
                    function (activeUser) {
                        if (activeUser) {
                            var options = RequestManagementService.getRequest();

                            options = RequestManagementService.setModel(options, 'analytic_videos');
                            options = RequestManagementService.setAction(options, 'add');

                            newAnalyticVideo.user_id = activeUser.id;
                            newAnalyticVideo.watch_datetime = AnalyticsService.getCurrentDate();

                            options = RequestManagementService.setData(options, newAnalyticVideo);

                            return DataManagementService.request(options);
                        }

                        return false;
                    }
                );
            } else {
                return ErrorService.addWarning(
                    'The video watch time is not enough to record (' + watchTime + ' vs ' + requiredWatchTime + ').'
                );
            }
        }


        AnalyticsService.getAnalyticVideos = getAnalyticVideos;
        function getAnalyticVideos(user, options) {
            if (!DefaultVariableService.isObject(user)) {
                return UserService.getUser().then(
                    function(user) {
                        if (user) {
                            return AnalyticsService.loadAnalyticVideos(user, options);
                        }

                        return false;
                    }
                );
            }

            return AnalyticsService.loadAnalyticVideos(user, options);
        }

        AnalyticsService.loadAnalyticVideos = loadAnalyticVideos;
        function loadAnalyticVideos(user, options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'analytic_videos');
            options = RequestManagementService.setAction(options, 'get');

            options = RequestManagementService.setParams(
                options,
                [
                    'join=INNER',
                    'user_id=' + user.id,
                    'Videos'
                ]
            );

            return ListManager.getPage(options);
        }

        AnalyticsService.getAnalyticStateVisits = getAnalyticStateVisits;
        function getAnalyticStateVisits(user, options) {
            if (!DefaultVariableService.isObject(user)) {
                return UserService.getUser().then(
                    function(user) {
                        if (user) {
                            return AnalyticsService.loadAnalyticStateVisits(user, options);
                        }

                        return false;
                    }
                );
            }

            return AnalyticsService.loadAnalyticStateVisits(user, options);
        }

        AnalyticsService.loadAnalyticStateVisits = loadAnalyticStateVisits;
        function loadAnalyticStateVisits(user, options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'analytic_state_visits');
            options = RequestManagementService.setAction(options, 'get');

            options = RequestManagementService.setParams(
                options,
                [
                    'user_id=' + user.id
                ]
            );

            if (!RequestManagementService.hasOrder(options)) {
                options = RequestManagementService.setOrder(
                    options,
                    [
                        '-transition_datetime'
                    ]
                );
            }

            return ListManager.getPage(options);
        }

        AnalyticsService.reset = reset;
        function reset() {
            AnalyticsService.previousDatetime = AnalyticsService.getCurrentDate();

            ListManager.reset();
        }

        var ListManager = ListManagementService.getListManager();

        AnalyticsService.reset();

        return AnalyticsService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicAnalytics').controller(
        'AnalyticManagementController',
        AnalyticManagementController
    );

    AnalyticManagementController.$inject = [
        'DefaultVariableService',
        'AnalyticsService',
        'LoginService'
    ];

    function AnalyticManagementController(
        DefaultVariableService,
        AnalyticsService,
        LoginService
    ) {
        var AnalyticManagementController = this;

        AnalyticManagementController.clear = clear;
        function clear() {
            AnalyticManagementController.reset();
            AnalyticsService.reset();
        }

        AnalyticManagementController.forceRefresh = forceRefresh;
        function forceRefresh() {
            AnalyticManagementController.clear();
            AnalyticManagementController.init();
        }

        AnalyticManagementController.displayStates = displayStates;
        function displayStates() {
            AnalyticManagementController.showStates = true;
        }

        AnalyticManagementController.displayVideos = displayVideos;
        function displayVideos() {
            AnalyticManagementController.showStates = false;
        }

        AnalyticManagementController.toggleUserAnalytics = toggleUserAnalytics;
        function toggleUserAnalytics(user) {
            AnalyticManagementController.user = DefaultVariableService.getObject(
                user
            );
        }

        AnalyticManagementController.reset = reset;
        function reset() {
            AnalyticManagementController.resetUser();
        }


        AnalyticManagementController.resetUser = resetUser;
        function resetUser() {
            AnalyticManagementController.user = DefaultVariableService.getObject();
        }

        AnalyticManagementController.init = init;
        function init() {
            AnalyticManagementController.reset();
        }

        LoginService.register(AnalyticManagementController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicAnalytics').directive(
        'ionicAnalyticManagement',
        ionicAnalyticManagement
    );

    function ionicAnalyticManagement() {
        return {
            controller:   'AnalyticManagementController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<ionic-user-management data-ng-if="ctrl.user.id === 0" allow-create="false" on-select="ctrl.toggleUserAnalytics"></ionic-user-management><div data-ng-if="ctrl.user.id !== 0"><h1>{{ ctrl.user.full_name | uppercase }}\'S ANALYTICS</h1><div class="row tabs-icon-top"><a class="col tab-item" data-ng-class="{ \'accept-color\': ctrl.displayStates }" data-ng-click="ctrl.displayStates()"><i class="icon ion-map"></i> STATES</a> <a class="col tab-item" data-ng-class="{ \'accept-color\': ctrl.displayStates }" data-ng-click="ctrl.displayVideos()"><i class="icon ion-ios-videocam"></i> VIDEOS</a></div><ionic-user-analytic-state-visits data-ng-if="ctrl.showStates" on-cancel="ctrl.resetUser" user="ctrl.user"></ionic-user-analytic-state-visits><ionic-user-analytic-videos data-ng-if="!ctrl.showStates" on-cancel="ctrl.resetUser" user="ctrl.user"></ionic-user-analytic-videos></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicAnalytics').controller(
        'UserAnalyticVideosController',
        UserAnalyticVideosController
    );

    UserAnalyticVideosController.$inject = [
        'DefaultVariableService',
        'AnalyticsService',
        'LoginService',
        '$scope'
    ];

    function UserAnalyticVideosController(
        DefaultVariableService,
        AnalyticsService,
        LoginService,
        $scope
    ) {
        var UserAnalyticVideosController = this;

        $scope.$watch(
            'onCancel',
            function(onCancel) {
                UserAnalyticVideosController.onCancel = DefaultVariableService.get(
                    onCancel,
                    false
                );
            }.bind(UserAnalyticVideosController)
        );

        $scope.$watch(
            'user',
            function(user) {
                UserAnalyticVideosController.user = DefaultVariableService.getObject(
                    user
                );

                UserAnalyticVideosController.loadAnalyticVideos();
            }.bind(UserAnalyticVideosController)
        );

        UserAnalyticVideosController.cancel = cancel;
        function cancel() {
            UserAnalyticVideosController.onCancel();
        }

        UserAnalyticVideosController.clear = clear;
        function clear() {
            UserAnalyticVideosController.reset();
            AnalyticsService.reset();
        }

        UserAnalyticVideosController.forceRefresh = forceRefresh;
        function forceRefresh() {
            UserAnalyticVideosController.clear();
            UserAnalyticVideosController.init();

            UserAnalyticVideosController.loadAnalyticVideos();
        }

        UserAnalyticVideosController.loadAnalyticVideos = loadAnalyticVideos;
        function loadAnalyticVideos(options) {
            UserAnalyticVideosController.isLoadingAnalytics = true;

            return AnalyticsService.getAnalyticVideos(
                UserAnalyticVideosController.user,
                options
            ).then(
                function(data) {
                    if (data) {
                        UserAnalyticVideosController.analytics = DefaultVariableService.getArray(
                            data.analytic_videos
                        );

                        UserAnalyticVideosController.notFound = false;
                    } else {
                        UserAnalyticVideosController.notFound = true;
                    }

                    UserAnalyticVideosController.canLoadMore = DefaultVariableService.get(
                        data.canLoadMore,
                        false
                    );

                    UserAnalyticVideosController.count = DefaultVariableService.getInteger(
                        data.count
                    );

                    return data;
                }
            ).finally(
                function() {
                    UserAnalyticVideosController.isLoadingAnalytics = false;
                }
            );
        }

        UserAnalyticVideosController.reset = reset;
        function reset() {
            UserAnalyticVideosController.analytics = [];
            UserAnalyticVideosController.canLoadMore = true;

            UserAnalyticVideosController.columns = [
                {
                    position: 1,
                    title:    'VIDEO',
                    key:      'video.title',
                    canOrder: false
                },
                {
                    position: 2,
                    title:    'WATCHED',
                    key:      'watch_datetime',
                    canOrder: false
                },
                {
                    position: 3,
                    title:    'PLAY',
                    key:      'play_time',
                    canOrder: false
                },
                {
                    position: 4,
                    title:    'STOP',
                    key:      'stop_time',
                    canOrder: false
                }
            ];

            UserAnalyticVideosController.count = 0;

            UserAnalyticVideosController.isLoadingAnalytics = true;

            UserAnalyticVideosController.notFound = false;

            UserAnalyticVideosController.users = [];
        }

        UserAnalyticVideosController.init = init;
        function init() {
            UserAnalyticVideosController.reset();
        }

        LoginService.register(UserAnalyticVideosController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicAnalytics').directive(
        'ionicUserAnalyticVideos',
        ionicUserAnalyticVideos
    );

    function ionicUserAnalyticVideos() {
        return {
            controller:   'UserAnalyticVideosController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                onCancel:   '=',
                user:       '='
            },
            template:'<ionic-data-table columns="ctrl.columns" on-request="ctrl.loadAnalyticVideos"></ionic-data-table><button data-ng-if="ctrl.onCancel" class="button button-full decline-button" data-ng-click="ctrl.cancel()">CANCEL</button>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicAnalytics').controller(
        'UserAnalyticStateVisitsController',
        UserAnalyticStateVisitsController
    );

    UserAnalyticStateVisitsController.$inject = [
        'DefaultVariableService',
        'AnalyticsService',
        'LoginService',
        '$scope'
    ];

    function UserAnalyticStateVisitsController(
        DefaultVariableService,
        AnalyticsService,
        LoginService,
        $scope
    ) {
        var UserAnalyticStateVisitsController = this;

        $scope.$watch(
            'onCancel',
            function(onCancel) {
                UserAnalyticStateVisitsController.onCancel = DefaultVariableService.get(
                    onCancel,
                    false
                );
            }.bind(UserAnalyticStateVisitsController)
        );

        $scope.$watch(
            'user',
            function(user) {
                UserAnalyticStateVisitsController.user = DefaultVariableService.getObject(
                    user
                );

                UserAnalyticStateVisitsController.loadAnalyticStateVisits();
            }.bind(UserAnalyticStateVisitsController)
        );

        UserAnalyticStateVisitsController.cancel = cancel;
        function cancel() {
            UserAnalyticStateVisitsController.onCancel();
        }

        UserAnalyticStateVisitsController.clear = clear;
        function clear() {
            UserAnalyticStateVisitsController.reset();
            AnalyticsService.reset();
        }

        UserAnalyticStateVisitsController.forceRefresh = forceRefresh;
        function forceRefresh() {
            UserAnalyticStateVisitsController.clear();
            UserAnalyticStateVisitsController.init();

            UserAnalyticStateVisitsController.loadAnalyticStateVisits();
        }

        UserAnalyticStateVisitsController.loadAnalyticStateVisits = loadAnalyticStateVisits;
        function loadAnalyticStateVisits(options) {
            UserAnalyticStateVisitsController.isLoadingAnalytics = true;

            return AnalyticsService.getAnalyticStateVisits(
                UserAnalyticStateVisitsController.user,
                options
            ).then(
                function(data) {
                    if (data) {
                        UserAnalyticStateVisitsController.analytics = DefaultVariableService.getArray(
                            data.analytic_state_visits
                        );

                        UserAnalyticStateVisitsController.notFound = false;
                    } else {
                        UserAnalyticStateVisitsController.notFound = true;
                    }

                    UserAnalyticStateVisitsController.canLoadMore = DefaultVariableService.get(
                        data.canLoadMoreActivities,
                        false
                    );

                    UserAnalyticStateVisitsController.count = DefaultVariableService.getInteger(
                        data.count
                    );

                    return data;
                }
            ).finally(
                function() {
                    UserAnalyticStateVisitsController.isLoadingAnalytics = false;
                }
            );
        }

        UserAnalyticStateVisitsController.reset = reset;
        function reset() {
            UserAnalyticStateVisitsController.analytics = [];

            UserAnalyticStateVisitsController.canLoadMore = true;

            UserAnalyticStateVisitsController.columns = [
                {
                    position: 1,
                    title:    'DATE',
                    key:      'transition_datetime'
                },
                {
                    position: 2,
                    title:    'FROM',
                    key:      'from_state'
                },
                {
                    position: 3,
                    title:    'TO',
                    key:      'to_state'
                },
                {
                    position: 4,
                    title:    'PARAMS',
                    key:      'params'
                },
                {
                    position: 5,
                    title:    'SECONDS',
                    key:      'seconds'
                }
            ];

            UserAnalyticStateVisitsController.count = 0;

            UserAnalyticStateVisitsController.isLoadingAnalytics = true;

            UserAnalyticStateVisitsController.notFound = false;
        }

        UserAnalyticStateVisitsController.init = init;
        function init() {
            UserAnalyticStateVisitsController.reset();
        }

        LoginService.register(UserAnalyticStateVisitsController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicAnalytics').directive(
        'ionicUserAnalyticStateVisits',
        ionicUserAnalyticStateVisits
    );

    function ionicUserAnalyticStateVisits() {
        return {
            controller:   'UserAnalyticStateVisitsController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                onCancel:   '=',
                user:       '='
            },
            template:'<ionic-data-table columns="ctrl.columns" on-request="ctrl.loadAnalyticStateVisits"></ionic-data-table><button data-ng-if="ctrl.onCancel" class="button button-full decline-button" data-ng-click="ctrl.cancel()">CANCEL</button>'
        };
    }
})();